<p *ngIf="!selectedOrder && !selectedOrderDetails" class="instruction" [@fade]>
  (Select an Order to view/manage its details)
</p>

<div *ngIf="selectedOrder && selectedOrderDetails" [@fade]>
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1" *ngIf="details">
      <a class="ngbNavLink" ngbNavLink>Details</a>
      <ng-template ngbNavContent>
        <!-- Patient Information -->
        <div *ngIf="selectedOrder && selectedOrderDetails" class="d-flex flex-wrap gap-3 mt-3">
          <h6>
            Patient Name:
            <strong
              >{{ selectedOrder.patient.last_name }},
              {{ selectedOrder.patient.first_name }}</strong
            >
          </h6>
          <div class="rename-btn">
            <i
              class="fa fa-pencil-square fa-lg pointer"
              (click)="updatePatient()"
              aria-hidden="true"
            ></i>
          </div>
          <h6 class="ml-5">
            DOB:
            <strong>{{ selectedOrder.patient.date_of_birth }}</strong>
          </h6>
          <h6 class="ml-5">
            MRN: <strong>{{ selectedOrder.patient.med_rec_nbr }}</strong>
          </h6>
          <h6 class="ml-5">
            <label>
              Enrolled:
              <input
                onclick="this.checked=!this.checked;"
                type="checkbox"
                [checked]="patient_enrolled"
            /></label>
          </h6>
            <h6 class="ml-3">Primary Payer ID: <strong>{{ selectedOrderDetails?.primary_payer_id || 'N/A' }}</strong></h6>
            <h6 class="ml-3">Primary Payer Name: <strong>{{ selectedOrderDetails?.primary_payer_name || 'N/A' }}</strong></h6>
            <h6 class="ml-3">Primary Payer Group: <strong>{{ selectedOrderDetails?.primary_payer_group || 'N/A' }}</strong></h6>
        </div>

        <!-- Order Details -->
        <div
          *ngIf="selectedOrder && selectedOrderDetails"
          class="order-details col-md-12 text-left pl-0"
        >
          <div class="row pb-1">
            <div class="col-md-2 text-right order-div-col mt-1">Provider:</div>
            <div class="col-md-4 pl-0">
              <select
                class="order-inputs"
                (change)="changeOrderProvider($event)"
                *ngIf="userRole === 'practice_admin'"
              >
                <option
                  *ngFor="let provider of practiceProviders"
                  [value]="provider | json"
                  [selected]="selectedOrderProvider.id === provider.provider_id"
                >
                  {{ provider.description }}
                </option>
              </select>
              <input
                class="order-inputs"
                [value]="selectedOrder.provider.name"
                disabled
                readonly
                type="text"
                *ngIf="userRole != 'practice_admin'"
              />
            </div>
          </div>
          <div class="row pb-1">
            <div class="col-md-2 text-right order-div-col mt-1">
              Order Summary:
            </div>
            <div class="col-md-4 pl-0">
              <input
                class="order-inputs"
                #order_summary
                type="text"
                [(ngModel)]="selectedOrder.order_summary"
              />
            </div>
          </div>
          <div class="row pb-1">
            <div class="col-md-2 text-right order-div-col mt-1">Facility:</div>
            <div class="col-md-3 pl-0 mt-1">
              <select
                class="order-inputs"
                (change)="changeLocation($event)"
                *ngIf="userRole === 'practice_admin'"
              >
                <option
                  *ngFor="let loction of clientLocations"
                  [value]="loction.description"
                  [selected]="
                    selectedLocation?.description === loction.description
                  "
                >
                  {{ loction.description }}
                </option>
              </select>
              <input
                class="order-inputs"
                [value]="selectedOrder.order_facility"
                disabled
                readonly
                type="text"
                *ngIf="userRole != 'practice_admin'"
              />
            </div>
          </div>
          <ng-container *ngIf="showFacility">
            <div class="row pb-1">
              <div class="col-md-2 text-right order-div-col mt-1"></div>
              <div class="col-md-3 pl-0">
                <input
                  class="order-inputs"
                  type="text"
                  style="height: 25px"
                  (change)="facilityItem($event)"
                  placeholder="Add new location here..."
                />
              </div>
            </div>
          </ng-container>
          <div class="row pb-1">
            <div class="col-md-2 text-right order-div-col mt-1">
              Cell Phone:
            </div>
            <div class="col-md-4 pl-0">
              <input
                class="order-inputs"
                #patient_phone
                type="text"
                [(ngModel)]="selectedOrder.patient_phone"
                mask="(000) 000-0000"
              />
            </div>
          </div>
          <div class="row pb-1">
            <div class="col-md-2 text-right order-div-col mt-1">Notes:</div>
            <div class="col-md-4 pl-0">
              <textarea
                class="swalInput order-inputs"
                #physician_notes
                [(ngModel)]="selectedOrder.physician_notes"
                name="physician_notes"
                id="physician_notes"
                cols="36"
                rows="3"
                placeholder="Enter notes here..."
              ></textarea>
            </div>
          </div>
          <div class="row pb-1">
            <div class="col-md-2 text-right order-div-col mt-1">Date:</div>
            <div class="pl-0">
              <div class="form-group">
                <div class="input-group" style="margin-right: 0px">
                  <input
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    name="dp"
                    [(ngModel)]="orderDatePickerModel"
                    ngbDatepicker
                    #d="ngbDatepicker"
                  />
                  <div
                    class="input-group-append"
                    style="position: absolute; right: 0; height: 40px"
                  >
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d.toggle()"
                      type="button"
                    >
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pb-1">
            <div class="col-md-2 text-right order-div-col mt-1">Time:</div>
            <div class="pl-0">
              <div class="form-group" style="display: flex">
                <div class="input-group" style="margin-right: 0px">
                  <input
                    style="width: '65px'"
                    class="form-control"
                    name="hour"
                    [(ngModel)]="time.hour"
                    (click)="timeToggle = !timeToggle"
                  />
                  &nbsp;&nbsp;
                  <div style="font-weight: bold; font-size: large">:</div>
                  &nbsp;&nbsp;
                  <input
                    style="width: '100px'"
                    class="form-control"
                    name="minute"
                    [(ngModel)]="time.minute"
                    (click)="timeToggle = !timeToggle"
                  />
                  <div
                    class="input-group-append"
                    style="position: absolute; right: 0; height: 40px"
                  >
                    <button
                      class="btn btn-outline-secondary calendar"
                      name="toggleTime"
                      (click)="timeToggle = !timeToggle"
                      type="button"
                    >
                      <div *ngIf="!timeToggle">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                      </div>
                      <div *ngIf="timeToggle">
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </div>
                    </button>
                  </div>
                </div>
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                <button
                  style="width: '70px'"
                  class="form-control"
                  placeholder="yyyy-mm-dd"
                  name="ampm"
                  (click)="ampm === 'AM' ? (ampm = 'PM') : (ampm = 'AM')"
                >
                  {{ ampm }}
                </button>
              </div>
            </div>
          </div>
          <div class="row pb-1" *ngIf="timeToggle">
            <div class="col-md-2 text-right order-div-col mt-1"></div>
            <div class="col-md-4 pl-0">
              <div class="time-container">
                <div id="ampmcontainer" class="container">
                  <div id="ampmCard" class="card">
                    <div class="heading"></div>
                    <div class="ampm" (click)="ampm = 'AM'">AM</div>
                    <div class="ampm" (click)="ampm = 'PM'">PM</div>
                  </div>
                </div>
                <div class="container">
                  <div class="card">
                    <div class="heading"><b>Hours</b></div>
                    <div class="month">
                      <table>
                        <tr class="whiteTr">
                          <th (click)="time.hour = 01">01</th>
                          <th (click)="time.hour = 02">02</th>
                          <th (click)="time.hour = 03">03</th>
                        </tr>
                        <tr class="whiteTr">
                          <th (click)="time.hour = 04">04</th>
                          <th (click)="time.hour = 05">05</th>
                          <th (click)="time.hour = 06">06</th>
                        </tr>
                        <tr class="whiteTr">
                          <th (click)="time.hour = 07">07</th>
                          <th (click)="time.hour = 08">08</th>
                          <th (click)="time.hour = 09">09</th>
                        </tr>
                        <tr class="whiteTr">
                          <th (click)="time.hour = 10">10</th>
                          <th (click)="time.hour = 11">11</th>
                          <th (click)="time.hour = 12">12</th>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div id="minutesCard" class="card">
                    <div class="heading"><b>Minutes</b></div>
                    <div class="month">
                      <table>
                        <tr class="whiteTr">
                          <th (click)="time.minute = 05">5</th>
                          <th (click)="time.minute = 10">10</th>
                          <th (click)="time.minute = 15">15</th>
                        </tr>
                        <tr class="whiteTr">
                          <th (click)="time.minute = 20">20</th>
                          <th (click)="time.minute = 25">25</th>
                          <th (click)="time.minute = 30">30</th>
                        </tr>
                        <tr class="whiteTr">
                          <th (click)="time.minute = 35">35</th>
                          <th (click)="time.minute = 40">40</th>
                          <th (click)="time.minute = 45">45</th>
                        </tr>
                        <tr class="whiteTr">
                          <th (click)="time.minute = 50">50</th>
                          <th (click)="time.minute = 55">55</th>
                          <th (click)="time.minute = 60">60</th>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <ngb-timepicker
                name="tp"
                id="time"
                [(ngModel)]="time"
                [meridian]="'ON'"
              ></ngb-timepicker> -->
            </div>
          </div>
          <div class="row pb-1 mt-3 mb-2 details-actions">
            <button class="btn btn-danger" (click)="resetScheduledDate()">
              Reset Scheduled Date
              <i class="fa fa-repeat ml-1" aria-hidden="true"></i>
            </button>
            <button
              class="btn btn-primary style-btn ml-2"
              (click)="
                updateOrderDetails(
                  order_summary.value,
                  patient_phone.value,
                  physician_notes.value
                )
              "
            >
              Update <i class="fa fa-floppy-o ml-1" aria-hidden="true"></i>
            </button>
            <button
              *ngIf="userRole === 'practice_admin'"
              class="btn btn-danger ml-2 mr-4"
              (click)="deleteOrder()"
            >
              Delete Order <i class="fa fa-times ml-1" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <!-- Order Comments -->
        <div
          *ngIf="selectedOrderDetails"
          class="right__panel calendar-data__wrapper mb-4"
        >
          <div
            class="table-data__wrapper table-responsive calendar-data--table border-bottom"
          >
            <h6 style="margin-left: 20px; margin-top: 10px">Comments</h6>
            <app-table-pagination-client
              [headers]="orderCommentsTableHeaders"
              [data]="selectedOrderCommentsTableData"
              [hasActions]="false"
              [addContent]="addComment"
              [pageSize]="2"
              [currentPage]="1"
            >
            </app-table-pagination-client>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" *ngIf="steps">
      <a class="ngbNavLink" ngbNavLink>Steps</a>
      <ng-template ngbNavContent>
        <!-- Patient Information -->
        <div *ngIf="selectedOrder && selectedOrderDetails" class="d-flex flex-wrap gap-3 mt-3">
          <h6>
            Patient Name:
            <strong
              >{{ selectedOrder.patient.last_name }},
              {{ selectedOrder.patient.first_name }}</strong
            >
          </h6>
          <div class="rename-btn">
            <i
              class="fa fa-pencil-square fa-lg pointer"
              (click)="updatePatient()"
              aria-hidden="true"
            ></i>
          </div>
          <h6 class="ml-5">
            DOB: <strong></strong>
            {{ selectedOrder.patient.date_of_birth }}
          </h6>
          <h6 class="ml-5">
            MRN: <strong>{{ selectedOrder.patient.med_rec_nbr }}</strong>
          </h6>
          <h6 class="ml-5">
            <label>
              Enrolled:
              <input
                onclick="this.checked=!this.checked;"
                type="checkbox"
                [checked]="patient_enrolled"
            /></label>
          </h6>
          <h6 class="ml-3">Primary Payer ID: <strong>{{ selectedOrderDetails?.primary_payer_id || 'N/A' }}</strong></h6>
          <h6 class="ml-3">Primary Payer Name: <strong>{{ selectedOrderDetails?.primary_payer_name || 'N/A' }}</strong></h6>
          <h6 class="ml-3">Primary Payer Group: <strong>{{ selectedOrderDetails?.primary_payer_group || 'N/A' }}</strong></h6>
        </div>

        <!-- Order Steps -->
        <div
          *ngIf="selectedOrderDetails"
          class="right__panel calendar-data__wrapper mb-4"
          style="margin-top: 20px"
        >
          <div
            [ngClass]="
              !selectedStep
                ? 'table-data__wrapper table-responsive calendar-data--table border-bottom'
                : 'table-data__wrapper table-responsive calendar-data--table'
            "
          >
            <div class="d-flex steps-detail">
              <app-table-pagination-client
                #steps_table
                [headers]="orderStepsTableHeaders"
                [data]="selectedOrderStepsTableData"
                [selectContent]="selectStep"
                [selectedContent]="selectedStep"
                [paginateId]="3"
                [pageSize]="6"
                [currentPage]="1"
                [showRowDisplay]="false"
              >
              </app-table-pagination-client>
              <div class="notes">
                <h6>Notes</h6>
                <div class="text-editor">
                  <ckeditor [editor]="editor" [(ngModel)]="notes"></ckeditor>
                </div>

                <button
                  *ngIf="!isEditing; else updateButton"
                  class="btn btn-primary style-btn"
                  (click)="createOrderNote()"
                >
                  Create
                  <i class="fa fa-floppy-o ml-1" aria-hidden="true"></i>
                </button>
                <ng-template #updateButton>
                  <button
                    class="btn btn-primary style-btn"
                    (click)="saveUpdatedNote()"
                  >
                    Update
                    <i class="fa fa-check ml-1" aria-hidden="true"></i>
                  </button>
                </ng-template>
              </div>
              <div class="edit-steps">
                <div *ngIf="selectedStep" [@fade]>
                  <h6>
                    Step:
                    <input
                      class="order-inputs"
                      #order_step
                      type="text"
                      [disabled]="!selectedStep"
                      [value]="selectedStep?.step"
                    />
                  </h6>

                  <h6>
                    Status:
                    <select
                      class="order-inputs"
                      [disabled]="!selectedStep"
                      (change)="changeOrderStepStatus($event)"
                      *ngIf="orderStatusAll"
                    >
                      <option
                        *ngFor="let step of orderStatusAll"
                        [value]="step | json"
                        [selected]="selectedOrderStep?.id === step.id"
                      >
                        {{ step.description }}
                      </option>
                    </select>
                  </h6>
                  <button 
                  *ngIf="selectedStep?.step === 'Authorization' && (selectedOrderStep?.id === 2 || selectedOrderStep?.id === 3)"
                  class="btn btn-primary style-btn-create" (click)="createForm()">
                    Create Form
                    <i class="fa fa-floppy-o ml-1" aria-hidden="true"></i>
                  </button> 
                  <button *ngIf="selectedStep" class="btn btn-primary style-btn" (click)="updateOrderStep(order_step)">
                    Update
                    <i class="fa fa-floppy-o ml-1" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div (change)="(selectedOrderDetails.attachments || selectedAttachmentsTableData)">
              <div *ngIf="selectedOrderDetails" class="right__panel calendar-data__wrapper">
                
                <!-- Insurance forms -->
                <div *ngIf="selectedOrderDetails" class="right__panel insurance-forms__wrapper mb-4">
                  <div class="header-container">
                    <h6 class="notes-heading">Insurance Forms</h6>
                  </div>
                  <table class="table table-bordered custom-insurance-forms-table">
                    <thead>
                      <tr>
                        <th>File Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let form of InsuranceFormsData; let i = index">
                        <td>{{ form.file_name }}</td>
                        <td class="small-column">
                          <button
                          class="btn btn-primary name-change-btn action-btn"
                          (click)="previewInsuranceForm(form)"
                            title="Preview Form"
                          >
                            
                            <i style="color: white" class="fa fa-print" aria-hidden="true"></i>

                          </button>
                          <button
                            class="btn btn-danger delete-btn action-btn"
                            (click)="deleteInsuranceForm(form.id,form.order_id)"
                            title="Delete Form"
                          >
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                

                <!-- Notes Table -->
                <div
                  *ngIf="selectedOrderDetails"
                  class="right__panel calendar-data__wrapper mb-4"
                >
                  <div class="header-container">
                    <h6 class="notes-heading">Notes</h6>
                    <button
                      type="button"
                      ngbTooltip="Download Notes CSV"
                      (click)="downloadNotesCSV()"
                      placement="left"
                      class="btn add-order-btn"
                    >
                      <i class="fas fa-cloud-download"></i>
                    </button>
                  </div>
                  <table class="table table-bordered custom-notes-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Contact Time</th>
                        <th>Comments</th>
                        <th>Employee</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let note of OrderStepsNotesData">
                        <td class="small-column">{{ note.note_date }}</td>
                        <td class="small-column">{{ note.note_time }}</td>
                        <td><div [innerHTML]="note.description"></div></td>
                        <td class="small-column">{{ note.created_by }}</td>
                        <td class="small-column">
                          <button
                            class="btn btn-primary name-change-btn action-btn"
                            (click)="editNote(note)"
                            title="Edit Note"
                          >
                            <i class="fa fa-edit" aria-hidden="true"></i>
                          </button>
                          <button
                            class="btn btn-danger delete-btn action-btn"
                            (click)="deleteOrderNote(note.id)"
                            title="Delete Note"
                          >
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Order Attachments -->
                <div
                  *ngIf="selectedOrderDetails"
                  class="right__panel calendar-data__wrapper mb-4"
                  (dragover)="onDragOver($event)"
                  (dragleave)="onDragLeave($event)"
                  (drop)="onDrop($event)"
                  [class.drag-over]="isDragOver"
                  style="border: 2px dashed transparent"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                    style="
                      margin-bottom: 10px;
                      padding-left: 20px;
                      padding-right: 20px;
                    "
                  >
                    <h6>Attachments</h6>
                    <button
                      type="button"
                      ngbTooltip="Upload Attachment"
                      placement="left"
                      class="btn add-order-btn"
                      (click)="uploadAttachment()"
                    >
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                  </div>
                  <app-table-pagination-client
                    [headers]="attachementsTableHeader"
                    [data]="selectedAttachmentsTableData"
                    [hasActions]="true"
                    [previewFunction]="previewFunction"
                    [deleteFunction]="deleteOrderAttachment"
                    [pageSize]="5"
                    [paginateId]="4"
                    [currentPage]="1"
                  >
                  </app-table-pagination-client>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="selectedOrderDetails && selectedStep"
          class="right__panel calendar-data__wrapper mb-4"
        >
          <div
            class="table-data__wrapper table-responsive calendar-data--table border-bottom"
          >
            <h6 style="margin-left: 20px">Comments</h6>
            <app-table-pagination-client
              [headers]="orderStepCommentsTableHeaders"
              [data]="selectedOrderStepsCommentsTableData"
              [hasActions]="false"
              [addContent]="addStepComment"
              [pageSize]="2"
              [currentPage]="1"
            >
            </app-table-pagination-client>
          </div>
        </div>
      </ng-template>
    </li>
    <li
      [ngbNavItem]="3"
      *ngIf="
        benefits &&
        this.userRole !== 'location_user' &&
        this.userRole !== 'location_admin'
      "
    >
      <a class="ngbNavLink" ngbNavLink>Benefits</a>
      <ng-template ngbNavContent>
        <!-- Patient Information -->
        <div *ngIf="selectedOrder && selectedOrderDetails" class="d-flex flex-wrap gap-3 mt-3">
          <h6>
            Patient Name:
            <strong
              >{{ selectedOrder.patient.last_name }},
              {{ selectedOrder.patient.first_name }}</strong
            >
          </h6>
          <div class="rename-btn">
            <i
              class="fa fa-pencil-square fa-lg pointer"
              (click)="updatePatient()"
              aria-hidden="true"
            ></i>
          </div>
          <h6 class="ml-5">
            DOB: <strong>{{ selectedOrder.patient.date_of_birth }}</strong>
          </h6>
          <h6 class="ml-5">
            MRN: <strong>{{ selectedOrder.patient.med_rec_nbr }}</strong>
          </h6>
          <h6 class="ml-5">
            <label>
              Enrolled:
              <input
                onclick="this.checked=!this.checked;"
                type="checkbox"
                [checked]="patient_enrolled"
            /></label>
          </h6>
          <h6 class="ml-3">Primary Payer ID: <strong>{{ selectedOrderDetails?.primary_payer_id || 'N/A' }}</strong></h6>
          <h6 class="ml-3">Primary Payer Name: <strong>{{ selectedOrderDetails?.primary_payer_name || 'N/A' }}</strong></h6>
          <h6 class="ml-3">Primary Payer Group: <strong>{{ selectedOrderDetails?.primary_payer_group || 'N/A' }}</strong></h6>
        </div>

        <!-- Order Benefits -->
        <div
          *ngIf="selectedOrderDetails"
          class="right__panel calendar-data__wrapper mb-4"
        >
          <h6 class="mt-3" style="margin-left: 20px">
            Insurance:
            <select
              class="order-inputs"
              (change)="changeOrderBenefitInsurancePayer($event)"
              style="width: 15rem"
              [disabled]="selectedOrderDetails?.client?.payers?.client"
            >
              <option selected value="" disabled hidden>
                Select Insurance
              </option>
              <option
                *ngFor="let payer of selectedOrderDetails.client.payers"
                [value]="payer | json"
              >
                {{ payer.description }}
              </option>
            </select>
          </h6>
          <div
            class="table-data__wrapper table-responsive calendar-data--table border-bottom"
          >
            <h6 style="margin-top: 20px; margin-left: 20px">Procedures</h6>
            <div class="d-flex benefits-detail">
              <div style="position: relative">
                <button
                  *ngIf="benefitProcedureTableData"
                  class="ml-4 btn add-benefit-insurance-btn style-btn"
                  (click)="addOrderBenefitInsurance()"
                >
                  Add <i class="fa fa-plus ml-1" aria-hidden="true"></i>
                </button>
                <app-table-draggable
                  [headers]="orderBenefitProcedureTableHeaders"
                  [dataSource]="benefitProcedureTableData"
                  [payerMPPR]="selectedPayerMPPR"
                  [calculateAllowable]="calculateAllowable"
                  [reorderContent]="reorderOrderBenefitInsurance"
                  [totalAllowable]="totalAllowable"
                  [hasActions]="true"
                  [deleteFunction]="deleteOrderBenefits"
                >
                </app-table-draggable>
              </div>

              <div class="edit-benefits">
                <div *ngIf="selectedOrderDetails">
                  <h6>
                    Allowable: <span>$</span>
                    <input
                      #order_benefit_allowables
                      type="text"
                      (change)="
                        evaluateEstOutOfPocketOnChange(
                          order_benefit_allowables.value,
                          order_benefit_deductable.value,
                          order_benefit_amount_met.value,
                          order_benefit_coinsurance.value,
                          patient_responsibility.value
                        )
                      "
                      [min]="0"
                      [disabled]="!selectedOrderDetails"
                      [value]="
                        selectedOrderDetails?.benefits[0]?.allowable ||
                        totalAllowable
                      "
                      appTwoDigitDecimaNumber
                    />
                  </h6>
                  <h6>
                    Deductable: <span>$</span>
                    <input
                      #order_benefit_deductable
                      type="text"
                      (change)="
                        evaluateEstOutOfPocketOnChange(
                          order_benefit_allowables.value,
                          order_benefit_deductable.value,
                          order_benefit_amount_met.value,
                          order_benefit_coinsurance.value,
                          patient_responsibility.value
                        )
                      "
                      min="0"
                      [disabled]="!selectedOrderDetails"
                      [value]="selectedOrderDetails?.benefits[0]?.deductable"
                      appTwoDigitDecimaNumber
                    />
                  </h6>
                  <h6>
                    Amount Met: <span>$</span>
                    <input
                      #order_benefit_amount_met
                      type="text"
                      (change)="
                        evaluateEstOutOfPocketOnChange(
                          order_benefit_allowables.value,
                          order_benefit_deductable.value,
                          order_benefit_amount_met.value,
                          order_benefit_coinsurance.value,
                          patient_responsibility.value
                        )
                      "
                      min="0"
                      [disabled]="!selectedOrderDetails"
                      [value]="
                        selectedOrderDetails?.benefits[0]?.deductable_met
                      "
                      appTwoDigitDecimaNumber
                    />
                  </h6>
                  <h6>
                    Coinsurance: <span>%</span>
                    <input
                      #order_benefit_coinsurance
                      type="text"
                      (change)="
                        evaluateEstOutOfPocketOnChange(
                          order_benefit_allowables.value,
                          order_benefit_deductable.value,
                          order_benefit_amount_met.value,
                          order_benefit_coinsurance.value,
                          patient_responsibility.value
                        )
                      "
                      min="0"
                      max="100"
                      [disabled]="!selectedOrderDetails"
                      [value]="selectedOrderDetails?.benefits[0]?.coinsurance"
                      appTwoDigitDecimaNumber
                    />
                  </h6>
                  <h6>
                    Patient Responsibility: <span>$</span>
                    <input
                      type="text"
                      #patient_responsibility
                      (change)="
                        evaluateEstOutOfPocketOnChange(
                          order_benefit_allowables.value,
                          order_benefit_deductable.value,
                          order_benefit_amount_met.value,
                          order_benefit_coinsurance.value,
                          patient_responsibility.value
                        )
                      "
                      min="0"
                      [value]="
                        selectedOrderDetails?.benefits[0]
                          ?.patient_responsibility
                      "
                      appTwoDigitDecimaNumber
                    />
                  </h6>
                  <h6>
                    Out Of Pocket: <span>$</span>
                    <input type="text" disabled [value]="estimateOutOfPocket" />
                  </h6>

                  <button
                    class="btn btn-primary style-btn mb-4"
                    [disabled]="!canUpdateBenefit"
                    (click)="
                      updateOrderBenefit(
                        order_benefit_allowables.value,
                        order_benefit_deductable.value,
                        order_benefit_amount_met.value,
                        order_benefit_coinsurance.value,
                        patient_responsibility.value
                      )
                    "
                  >
                    Save <i class="fa fa-floppy-o ml-1" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="4" *ngIf="appointments">
      <a class="ngbNavLink" ngbNavLink>Appointments</a>
      <ng-template ngbNavContent>
        <!-- Patient Information -->
        <div *ngIf="selectedOrder && selectedOrderDetails" class="d-flex flex-wrap gap-3 mt-3">
          <h6>
            Patient Name:
            <strong
              >{{ selectedOrder.patient.last_name }},
              {{ selectedOrder.patient.first_name }}</strong
            >
          </h6>
          <div class="rename-btn">
            <i
              class="fa fa-pencil-square fa-lg pointer"
              (click)="updatePatient()"
              aria-hidden="true"
            ></i>
          </div>
          <h6 class="ml-5">
            DOB: <strong>{{ selectedOrder.patient.date_of_birth }}</strong>
          </h6>
          <h6 class="ml-5">
            MRN: <strong>{{ selectedOrder.patient.med_rec_nbr }}</strong>
          </h6>
          <h6 class="ml-5">
            <label>
              Enrolled:
              <input
                onclick="this.checked=!this.checked;"
                type="checkbox"
                [checked]="patient_enrolled"
            /></label>
          </h6>
          <h6 class="ml-3">Primary Payer ID: <strong>{{ selectedOrderDetails?.primary_payer_id || 'N/A' }}</strong></h6>
          <h6 class="ml-3">Primary Payer Name: <strong>{{ selectedOrderDetails?.primary_payer_name || 'N/A' }}</strong></h6>
          <h6 class="ml-3">Primary Payer Group: <strong>{{ selectedOrderDetails?.primary_payer_group || 'N/A' }}</strong></h6>
        </div>

        <!-- Order Appointments -->
        <div
          *ngIf="selectedOrderDetails"
          class="right__panel calendar-data__wrapper mb-4"
        >
          <div
            class="table-data__wrapper table-responsive calendar-data--table border-bottom"
          >
            <app-table-pagination-client
              [headers]="orderAppointmentsTableHeaders"
              [data]="selectedOrderAppointmentsTableData"
              [hasActions]="true"
              [addContent]="addOrderAppointment"
              [deleteFunction]="deleteOrderAppointment"
              [pageSize]="6"
              [paginateId]="4"
              [currentPage]="1"
            >
            </app-table-pagination-client>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="5" *ngIf="tasks">
      <a class="ngbNavLink" ngbNavLink>Tasks</a>
      <ng-template ngbNavContent>
        <!-- Patient Information -->
        <div *ngIf="selectedOrder && selectedOrderDetails" class="d-flex flex-wrap gap-3 mt-3">
          <h6>
            Patient Name:
            <strong
              >{{ selectedOrder.patient.last_name }},
              {{ selectedOrder.patient.first_name }}</strong
            >
          </h6>
          <div class="rename-btn">
            <i
              class="fa fa-pencil-square fa-lg pointer"
              (click)="updatePatient()"
              aria-hidden="true"
            ></i>
          </div>
          <h6 class="ml-5">
            DOB: <strong>{{ selectedOrder.patient.date_of_birth }}</strong>
          </h6>
          <h6 class="ml-5">
            MRN: <strong>{{ selectedOrder.patient.med_rec_nbr }}</strong>
          </h6>
          <h6 class="ml-5">
            <label>
              Enrolled:
              <input
                onclick="this.checked=!this.checked;"
                type="checkbox"
                [checked]="patient_enrolled"
            /></label>
          </h6>
          <h6 class="ml-3">Primary Payer ID: <strong>{{ selectedOrderDetails?.primary_payer_id || 'N/A' }}</strong></h6>
          <h6 class="ml-3">Primary Payer Name: <strong>{{ selectedOrderDetails?.primary_payer_name || 'N/A' }}</strong></h6>
          <h6 class="ml-3">Primary Payer Group: <strong>{{ selectedOrderDetails?.primary_payer_group || 'N/A' }}</strong></h6>
        </div>

        <!-- Order Tasks -->
        <div
          *ngIf="selectedOrderDetails"
          class="order-details col-md-12 text-left pl-0 mb-4"
        >
          <div class="row pb-1">
            <div class="col-md-2 text-right order-div-col">&nbsp;</div>
            <div class="col-md-3 pl-0 text-left">
              <h6>Assign a New Task</h6>
            </div>
          </div>
          <div class="row pb-1">
            <div class="col-md-2 text-right order-div-col mt-1">Form:</div>
            <div class="col-md-4 pl-0">
              <select
                class="order-inputs"
                (change)="onOrderTaskSelectChange($event)"
                style="width: 18rem"
                [disabled]="!selectedOrderDetails?.client?.client_forms"
              >
                <option selected value="" disabled hidden>Select a form</option>
                <option
                  *ngFor="let form of selectedOrderDetails.client.client_forms"
                  [value]="form | json"
                >
                  {{ form.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="row pb-1">
            <div class="col-md-2 text-right order-div-col mt-1">
              Task Description:
            </div>
            <div class="col-md-4 pl-0">
              <input class="order-inputs" #task_description type="text" />
            </div>
          </div>

          <div class="row pb-1">
            <div class="col-md-2 text-right order-div-col mt-1">Due Date:</div>
            <div class="pl-0">
              <div class="form-group">
                <div class="input-group" style="margin-right: 0px">
                  <input
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    name="dp"
                    [(ngModel)]="orderTaskDueDatePickerModel"
                    ngbDatepicker
                    #d="ngbDatepicker"
                  />
                  <div
                    class="input-group-append"
                    style="position: absolute; right: 0; height: 40px"
                  >
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d.toggle()"
                      type="button"
                    >
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pb-1">
            <div class="col-md-3 text-right order-div-col">&nbsp;</div>
            <div class="col-md-3 pl-0 text-center">
              <button
                class="btn btn-primary style-btn"
                (click)="assignFormToOrder(task_description.value)"
              >
                Assign <i class="fa fa-check ml-1" aria-hidden="true"></i>
              </button>
            </div>
          </div>

          <div
            class="table-data__wrapper table-responsive calendar-data--table border-bottom"
          >
            <app-table-pagination-client
              [headers]="orderFormsTableHeaders"
              [data]="selectedOrderTasksTableData"
              [hasActions]="false"
              [pageSize]="6"
              [paginateId]="5"
              [currentPage]="1"
            >
            </app-table-pagination-client>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="6" *ngIf="attachments">
      <a class="ngbNavLink" ngbNavLink>Attachments</a>
      <ng-template ngbNavContent>
        <!-- Patient Information -->
        <div *ngIf="selectedOrder && selectedOrderDetails" class="d-flex flex-wrap gap-3 mt-3">
          <h6 class="mt-2">
            Patient Name:
            <strong
              >{{ selectedOrder.patient.last_name }},
              {{ selectedOrder.patient.first_name }}</strong
            >
          </h6>
          <div class="rename-btn" style="margin-top: 5px">
            <i
              class="fa fa-pencil-square fa-lg pointer"
              (click)="updatePatient()"
              aria-hidden="true"
            ></i>
          </div>
          <h6 class="ml-5 mt-2">
            DOB: <strong>{{ selectedOrder.patient.date_of_birth }}</strong>
          </h6>
          <h6 class="ml-5 mt-2">
            MRN: <strong>{{ selectedOrder.patient.med_rec_nbr }}</strong>
          </h6>
          <h6 class="ml-5 mt-2">
            <label>
              Enrolled:
              <input
                onclick="this.checked=!this.checked;"
                type="checkbox"
                [checked]="patient_enrolled"
            /></label>
          </h6>
          <h6 class="ml-3">Primary Payer ID: <strong>{{ selectedOrderDetails?.primary_payer_id || 'N/A' }}</strong></h6>
          <h6 class="ml-3">Primary Payer Name: <strong>{{ selectedOrderDetails?.primary_payer_name || 'N/A' }}</strong></h6>
          <h6 class="ml-3">Primary Payer Group: <strong>{{ selectedOrderDetails?.primary_payer_group || 'N/A' }}</strong></h6>
          <div style="margin-left: auto; margin-right: 20px">
            <button
              type="button"
              ngbTooltip="Upload Attachment"
              placement="left"
              class="btn add-order-btn"
              (click)="uploadAttachment()"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div
          (change)="
            (selectedOrderDetails.attachments || selectedAttachmentsTableData)
          "
        >
          <div
            *ngIf="selectedOrderDetails"
            class="right__panel calendar-data__wrapper"
          >
            <!-- Order Attachments -->
            <div
              *ngIf="selectedOrderDetails"
              class="right__panel calendar-data__wrapper mb-4"
            >
              <div
                class="table-data__wrapper table-responsive calendar-data--table border-bottom"
              >
                <app-table-pagination-client
                  [headers]="attachementsTableHeader"
                  [data]="selectedAttachmentsTableData"
                  [hasActions]="true"
                  [previewFunction]="previewFunction"
                  [deleteFunction]="deleteOrderAttachment"
                  [pageSize]="5"
                  [paginateId]="4"
                  [currentPage]="1"
                >
                </app-table-pagination-client>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
