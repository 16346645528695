import { BenefitPayerProcedure } from './payer';
import { Client } from './client';
import { Provider, ProviderData } from './provider';
import { GenericResp } from '.';
import { stubString } from 'lodash';

export class OrderModel {
  patient_id: string;
  provider_id: string;
  order_summary: string;
  created_by: string;
  procedure_id: string;
  steps: Array<string> = [];
  procedure_date: string;
  procedure_time: string;
  is_consent_required: boolean = false;
  notify_patient: boolean = false;
  files: Array<any> = [];
}

export interface PatientOrder {
  id?: string;
  date: string;
  patient_phone: string;
  order_date: Date | string;
  order_facility: string;
  order_id: string;
  order_summary: string;
  provider: {
    id: string;
    name: string;
  };
  provider_name?: string;
  patient_name?: string;
  patient?: OrderPatient;
  physician_notes?:any;
}

export interface PatientPopup {
  code: number;
  message: string;
  data?: {
    currentPage: number;
    count: number;
    limit: number;
    nextPage: number;
    previousPage: number;
    total: number;
    data: OrderPatient[];
  };
  error?: any;
}

export interface AllOrderStatusData {
  id: number;
  description: string;
}

export interface GET_ALL_ORDER_STATUS_RESP {
  code: number;
  message: string;
  data?: AllOrderStatusData[];
  error?: any;
}

export interface OrderStatus {
  id: number;
  description: string;
  is_deleted: boolean;
}

export interface GET_ALL_ORDER_STEPS_RESP {
  code: number;
  message: string;
  data?: OrderStepsWithStatus;
  error?: any;
}

export interface OrderStepsWithStatus {
  steps: OrderSteps[];
  status: OrderStatus[];
}
export interface OrderSteps {
  step_name: string;
}

export interface OrderProcedure {
  id: string;
  provider_id: string;
  orderGroupId: string;
  orderSubgroupId: string;
  description: string;
}

export interface OrderComment {
  comment_id: number;
  orderMasterOrderId: string;
  title: string;
  comment: string;
  created_by: string;
  created_at: string;
  modified_by: string;
  modified_at: string;
  order_id: string;
}

export interface OrderBenefit {
  benefits_id: string;
  order_id: string;
  insurance_name: string;
  benefits_date: Date | string;
  allowable: number | string;
  deductable: number | string;
  deductable_met: number | string;
  coinsurance: number | string;
  est_out_of_pocket: string;
  patient_responsibility: number | string;
  created_by: string;
  modified_by: string;
}

export interface OrderAppointment {
  appointment_id: number;
  order_id: string;
  provider: Provider;
  location: string;
  event_type: string;
  appointment_date: Date | string;
  appointment_time: Date | string;
  created_by: string;
  modified_by: string;
  created_at: Date;
  modified_at: Date;
}

export interface OrderStep {
  step_id: string;
  description: string;
  status: OrderStatus;
  comments: OrderComment[];
}

export interface OrderDetail {
  primary_payer_name: any;
  primary_payer_id: any;
  primary_payer_group:any;
  client: Client;
  practice: string;
  provider: ProviderData;
  order_id: string;
  order_number: number;
  order_summary: string;
  primary_cpt: string;
  is_completed: boolean;
  is_patient_enrolled: boolean;
  order_notes: string;
  completed_at: Date | string;
  order_facility: string;
  physician_notes: string;
  scheduled_date: Date | string;
  consent_required: boolean;
  status: OrderStatus;
  procedure: OrderProcedure;
  benefits: OrderBenefit[];
  comments: OrderComment[];
  appointments: OrderAppointment[];
  forms: OrderForms[];
  attachments: any;
  steps: any;
}
export interface OrderAttachmentData {
  order_id: string;
  file_name: string;
  file_url: string;
  id: number;
}

export interface OrderInsuranceFormData {
  order_id: string;
  file_name: string;
  file_url: string;
  id: number;
}
export interface GET_ORDER_DETAILS_RESP {
  code: number;
  message: string;
  data?: OrderDetail;
  error?: any;
}

export interface OrderCommentData {
  comment_id: number;
  title: string;
  comment: string;
  date: Date | string;
}

export interface OrderStepsCommentData {
  comment_id: number;
  title: string;
  comment: string;
  date: Date | string;
}

export interface OrderNote {
  id: string;
  order_id: string;
  note_date: string;
  note_time: string;
  description: string;
  created_by?: string;
  created_by_email?: string;
  updated_at?: string;
  updated_by?: string;
  updated_by_email?: string;
}

export interface GET_ALL_NOTES_RESP {
  code: number;
  message: string;
  error?: any;
  data: OrderNote[];
}

export interface GET_ALL_INSURANCE_FORMS_RESP{
  code: number;
  message: string;
  error?: any;
  data: OrderInsuranceFormData[];
}

export interface OrderAppointmentData {
  appointment_id: number;
  id?: number;
  provider: string;
  location: string;
  event_type: string;
  date: Date | string;
  time: Date | string;
}

export interface OrderForms {
  id: string;
  task_type: string;
  task_description: string;
  due_date: Date | string;
  assign_date: Date | string;
  is_completed: boolean;
  form: {
    id: string;
    title: string;
  };
}

export interface OrderProvider {
  id: string;
  name: string;
}

export interface OrderSelectedStep {
  id: number;
  name: string;
  comments?: OrderComment[];
}
export interface OrderStepData {
  id: string;
  step: string;
  status: string;
  status_detail: OrderStatus;
  comments: OrderComment[];
}

export interface OrderTasksData {
  task_id: string;
  form_id: string;
  task_type: string;
  task_description: string;
  due_date: string | Date;
  assign_date: string | Date;
  completed: string;
  form_title: string;
}

export interface AddOrderCommentRespData {
  id: number;
  order_id: string;
  title: string;
  comment: string;
  created_at: string;
  created_by: string;
}

export interface ADD_ORDER_COMMENT_RESP {
  code: number;
  message: string;
  data?: AddOrderCommentRespData;
  error?: any;
}

export interface ADD_ORDER_APPOINTMENT_REQ {
  provider_id: string;
  location: string;
  event_type: string;
  appointment_date: Date | string;
  appointment_time: Date | string;
  created_by: string;
  notify_patient: boolean;
}

export interface AddOrderAppointmentRespData {
  id: number;
  order_id: string;
  provider_id: string;
  location: string;
  event_type: string;
  created_at: Date | string;
  created_by: string;
}

export interface ADD_ORDER_APPOINTMENT_RESP {
  code: number;
  message: string;
  data?: any;
  error?: AddOrderAppointmentRespData;
}

export interface PatientOrdersData {
  contract_description: string;
  order_id: string;
  order_number: number;
  order_summary: string;
  primary_cpt: string;
  is_completed: boolean;
  completed_at: Date | string;
  order_facility: string;
  physician_notes: string;
  consent_required: boolean;
  order_date: Date | string;
  created_at: Date | string;
  order_status: OrderSelectedStep;
  provider: OrderProvider;
  patient: OrderPatient;
  patient_phone: string;
  is_benefit_done: boolean;
}

export interface GET_PATIENT_ORDERS {
  code: number;
  message: string;
  data?: {
    count: number;
    data: PatientOrdersData[];
    enrolled: boolean;
  };
  error?: any;
}

export interface PatientOrdersTableData {
  id?: string;
  order_id: string;
  provider_name: string;
  order_summary: string;
  date: Date | string;
  order_facility: string;
  order_date: Date | string;
  created_at: Date | string;
  provider: OrderProvider;
  patient?: OrderPatient;
  is_benefit_done: string;
}

export interface documentsTableData {
  order_id: string;
  file_name: string;
  file_url: string;
  id: number;
  name?: string;
  category?: string;
  date_of_service?: Date | string;
  created_by?: string;
}

export interface UPDATE_ORDER_DETAIL_REQ {
  notify_patient: boolean;
  provider_id?: string;
  order_summary?: string;
  order_facility?: string;
  patient_phone?: string;
  physician_notes?: string;
  scheduled_date?: Date | string;
}

export interface UPDATE_ORDER_STEP_REQ {
  notify_patient: boolean;
  step_description: string;
  step_status: number;
}

export interface UPDATE_ORDER_RESP {
  code: number;
  message: string;
}

export interface UPDATE_ORDER_BENEFIT_REQ {
  allowable: number;
  deductable: number;
  deductable_met: number;
  coinsurance: number;
  created_by: string;
  modified_by: string;
  patientResponsibility: number;
}

export interface CREATE_ORDER_BENEFIT_RESP {
  code: number;
  message: string;
  data?: OrderBenefit;
  error?: any;
}

export interface OrderPatient {
  id: string;
  patient_row_id: string;
  patient_room: string;
  patient_location: string;
  patient_status: number;
  patient_room_time: Date | string;
  patient_complete: number;
  patient_name: string;
  date_of_birth: string;
  cell_phone: string;
  med_rec_nbr: string;
  first_name: string;
  last_name: string;
  appointment_date: Date | string;
  provider: {
    description: string;
    provider_id: string;
  };
}

export interface OrdersData {
  order_id: string;
  order_number: number;
  order_summary: string;
  primary_cpt: string;
  is_completed: boolean;
  completed_at: Date | string;
  order_facility: string;
  physician_notes: string;
  consent_required: boolean;
  order_date: Date | string;
  created_at: Date | string;
  order_status: OrderSelectedStep;
  provider: OrderProvider;
  patient: OrderPatient;
  is_benefit_done: boolean;
  last_updated?: Date | string;
}

export interface GET_ALL_ORDERS_RESP {
  code: number;
  message: string;
  error?: any;
  data?: {
    previousPage: number | null;
    currentPage: number;
    nextPage: number | null;
    total: number;
    limit: number;
    data: OrdersData[];
  };
}

export interface CREATE_ORDER_BENEFIT_INSURANCE_REQ_BODY extends BenefitPayerProcedure {
  payer_id: string;
}

export interface CREATE_ORDER_BENEFIT_INSURANCE_RESP extends GenericResp {
  data: OrderBenefitInsurance;
}

export interface OrderBenefitInsurance extends BenefitPayerProcedure {
  cpt_code: number;
  order_id: string;
  payer_id: string;
  contract_description: string;
  created_at: string | Date;
  modified_at: string | Date;
}

export interface AssignFormToOrderReqData {
  notify_patient: boolean;
  patient_id: string;
  form_id: string;
  task_description: string;
  due_date: string | Date;
}

export interface GET_ORDER_ASSIGNED_FORMS_RESP extends GenericResp {
  data: OrderAssignedForms[];
}

export interface OrderAssignedForms {
  id: string;
  task_type: string;
  task_description: string;
  due_date: string | Date;
  assign_date: string | Date;
  is_completed: boolean;
  form: {
    id: string;
    title: string;
  };
}

export interface GET_PATIENT_APPOINTMENTS_RESP {
  code: number;
  message: string;
  data?: AppointmentsData[];
  error?: any;
}

export interface AppointmentsData {
  id: string;
  appt_id: string;
  event: string;
  convert_date: string;
  convert_time: string;
  provider: {
    description: string;
  };
  location: {
    description: string;
  };
  unique_patient: {
    med_rec_nbr: string;
  };
}

export interface appointmentsTableData {
  description: string;
  date: string;
  time: string;
  provider_name: string;
  location: string;
}

export interface ORDER_NOTES_REQ_BODY {
  notes: string;
}
