import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { promise } from 'protractor';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import {
  ADD_ORDER_APPOINTMENT_REQ,
  ADD_ORDER_APPOINTMENT_RESP,
  ADD_ORDER_COMMENT_RESP,
  AssignFormToOrderReqData,
  BenefitPayerProcedure,
  CREATE_ORDER_BENEFIT_INSURANCE_REQ_BODY,
  CREATE_ORDER_BENEFIT_INSURANCE_RESP,
  CREATE_ORDER_BENEFIT_RESP,
  GenericResp,
  GET_ALL_ORDERS_RESP,
  GET_ALL_ORDER_STATUS_RESP,
  GET_ORDER_ASSIGNED_FORMS_RESP,
  GET_ORDER_BENEFIT_CPT_DETAILS,
  GET_ORDER_BENEFIT_PAYER_PROCEDURES,
  GET_ORDER_DETAILS_RESP,
  GET_PATIENT_ORDERS,
  GET_PAYER_MPPR_RESP,
  UPDATE_ORDER_RESP,
  PatientPopup,
  GET_ALL_ORDER_STEPS_RESP,
  ORDER_NOTES_REQ_BODY,
  GET_PROVIDER_RESP,
  GET_ALL_NOTES_RESP,
  OrderStepData,
  OrderNote,
  GET_ALL_INSURANCE_FORMS_RESP,
} from '../models';
import { param } from 'jquery';
import { Options } from 'selenium-webdriver';
import { values } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  user = JSON.parse(localStorage.getItem('user'));
  constructor(private http: HttpClient, private db: AngularFireDatabase) {}

  async getOrderByPatientRowId(
    practice_id: string,
    provider_id: string,
    patient_id: string
  ): Promise<any> {
    return await this.http
      .post<any>(environment.Order_Page_Details, {
        practice_id,
        provider_id,
        patient_id,
      })
      .toPromise();
  }

  async createOrder(orderReq: any): Promise<any> {
    return await this.http
      .post<any>(environment.Create_Order, orderReq)
      .toPromise();
  }
  // async uploadOrderAttachment(
  //   practice_id: string,
  //   client_id: string,
  //   filesArray: any
  // ): Promise<any>
  // {

  //   // const formData = new FormData();
  //   // formData.append('files', filesArray);

  //   const formData1 = new FormData()
  //   formData1.append("practice_id",practice_id)

  //   formData1.append("client_id",client_id)
  //   formData1.append("files",filesArray)

  //   const data = {
  //     practice_id: practice_id,
  //     client_id: client_id,

  //   };
  //   const order_id: string = '0';

  //   // console.log(formData)

  //   // async createClientForm(practice_id: string, client_id: string, data: CREATE_FORM_REQ_BODY): Promise<GenericResp> {
  //   return await this.http
  //     .post<any>(
  //       `${environment.API_URL}order/${order_id}/attachment`,
  //       formData1
  //     )
  //     .toPromise();
  // }

  async getPatientOrders(
    patient_id: string | number,
    client_id: string,
    practice_id: string
  ): Promise<GET_PATIENT_ORDERS> {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http
      .post<GET_PATIENT_ORDERS>(
        environment.GET_PATIENT_ORDERS,
        {
          patient_id,
          client_id,
          practice_id,
        },
        { headers: { Authorization: `${user.stsTokenManager.accessToken}` } }
      )
      .toPromise();
  }

  async getPatientOrderDetails(
    order_id: string
  ): Promise<GET_ORDER_DETAILS_RESP> {
    return this.http
      .get<GET_ORDER_DETAILS_RESP>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}`
      )
      .toPromise();
  }

  async addOrderComment(
    order_id: string,
    notify_patient: boolean,
    title: string,
    comment: string,
    created_by: string
  ): Promise<ADD_ORDER_COMMENT_RESP> {
    return this.http
      .post<ADD_ORDER_COMMENT_RESP>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}/comment`,
        {
          notify_patient,
          title,
          comment,
          created_by,
        }
      )
      .toPromise();
  }

  async addStepOrderComment(
    order_id: string,
    step_id: string,
    notify_patient: boolean,
    title: string,
    comment: string,
    created_by: string
  ): Promise<ADD_ORDER_COMMENT_RESP> {
    return this.http
      .post<ADD_ORDER_COMMENT_RESP>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}/step/${step_id}/comment`,
        {
          notify_patient,
          title,
          comment,
          created_by,
        }
      )
      .toPromise();
  }

  async updatePatientOrderDetails(
    order_id: string,
    data: {}
  ): Promise<UPDATE_ORDER_RESP> {
    return this.http
      .put<UPDATE_ORDER_RESP>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}`,
        data
      )
      .toPromise();
  }

  getProviders(practice_id: string): Observable<GET_PROVIDER_RESP> {
    return this.http.post<GET_PROVIDER_RESP>(environment.GET_PROVIDERS, {
      practice_id,
    });
  }

  async updateOrderReadStatus(order_id: string, status: boolean): Promise<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http
      .put<any>(
        `${environment.BASE_URL}/order/${order_id}/read-status/${status}`,
        {},
        {
          headers: { Authorization: `${user.stsTokenManager.accessToken}` },
        }
      )
      .toPromise();
  }

  async getAllOrderStatus(): Promise<GET_ALL_ORDER_STATUS_RESP> {
    return this.http
      .get<GET_ALL_ORDER_STATUS_RESP>(environment.GET_ALL_ORDER_STATUS)
      .toPromise();
  }

  async updatePatientOrderStep(
    order_id: string,
    step_id: string,
    data: {}
  ): Promise<UPDATE_ORDER_RESP> {
    return this.http
      .put<UPDATE_ORDER_RESP>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}/step/${step_id}`,
        data
      )
      .toPromise();
  }

  async createPatientOrderBenefit(
    order_id: string,
    data: {}
  ): Promise<CREATE_ORDER_BENEFIT_RESP> {
    return this.http
      .post<CREATE_ORDER_BENEFIT_RESP>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}/benefit`,
        data
      )
      .toPromise();
  }

  async updatePatientOrderBenefit(
    order_id: string,
    benefits_id: string,
    data: {}
  ): Promise<UPDATE_ORDER_RESP> {
    return this.http
      .put<UPDATE_ORDER_RESP>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}/benefit/${benefits_id}`,
        data
      )
      .toPromise();
  }

  async deletePatientOrderAppointment(
    order_id: string,
    appointment_id: number,
    notify_patient: boolean
  ): Promise<GenericResp> {
    return this.http
      .delete<GenericResp>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}/appointment/${appointment_id}`,
        {
          headers: { notify_patient: `${notify_patient}` },
        }
      )
      .toPromise();
  }

  async deletePatientOrderBenefitsInsurace(
    order_id: string,
    benefits_insurace_id: number
  ): Promise<any> {
    return this.http
      .delete<any>(
        `${environment.API_URL}web/order/${order_id}/benefit_insurance/${benefits_insurace_id}`
      )
      .toPromise();
  }

  async addOrderAppointment(
    order_id: string,
    data: ADD_ORDER_APPOINTMENT_REQ
  ): Promise<ADD_ORDER_APPOINTMENT_RESP> {
    return this.http
      .post<ADD_ORDER_APPOINTMENT_RESP>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}/appointment`,
        data
      )
      .toPromise();
  }

  async getAllOrders(
    practice_id: string,
    page: number = 1,
    limit: number = 10,
    orderBy: {},
    search: string,
    searchCriteria: string,
    filterByStatus: {},
    is_archived: boolean
  ): Promise<GET_ALL_ORDERS_RESP> {
    return this.http
      .get<GET_ALL_ORDERS_RESP>(
        `${environment.GET_ALL_ORDERS}/practice/${practice_id}`,
        {
          params: {
            page: `${page}`,
            limit: `${limit}`,
            orderBy: JSON.stringify(orderBy),
            search,
            searchCriteria,
            filterByStatus: JSON.stringify(filterByStatus),
            is_archived: `${!!is_archived}`,
          },
        }
      )
      .toPromise();
  }

  async getAllNotes(order_id: string): Promise<GET_ALL_NOTES_RESP> {
    return this.http
      .get<GET_ALL_NOTES_RESP>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}/notes`
      )
      .toPromise();
  }

  async getBenefitsPayerProcedures(
    order_id: string,
    payer_id: string
  ): Promise<GET_ORDER_BENEFIT_PAYER_PROCEDURES> {
    return this.http
      .get<GET_ORDER_BENEFIT_PAYER_PROCEDURES>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}/payer/${payer_id}/benefit_insurance`
      )
      .toPromise();
  }

  async createOrderBenefitInsurance(
    order_id: string,
    data: CREATE_ORDER_BENEFIT_INSURANCE_REQ_BODY
  ): Promise<CREATE_ORDER_BENEFIT_INSURANCE_RESP> {
    return this.http
      .post<CREATE_ORDER_BENEFIT_INSURANCE_RESP>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}/benefit_insurance`,
        data
      )
      .toPromise();
  }

  async getPayerMPPR(payer_id: string): Promise<GET_PAYER_MPPR_RESP> {
    return this.http
      .get<GET_PAYER_MPPR_RESP>(`${environment.GET_PAYER_MPPR}/${payer_id}`)
      .toPromise();
  }

  toggleOrder(orderId: string, isArchive): any {
    return this.http
      .post<ADD_ORDER_APPOINTMENT_RESP>(
        `${environment.TOGGLE_ORDER}${orderId}/archive/${isArchive}`,
        null
      )
      .toPromise();
  }

  async reorderBenefitsInsurance(
    order_id: string,
    data: BenefitPayerProcedure[]
  ): Promise<UPDATE_ORDER_RESP> {
    return this.http
      .put<UPDATE_ORDER_RESP>(
        `${environment.GET_PATIENT_ORDER_DETAILS}${order_id}/reorder/benefit_insurance`,
        data
      )
      .toPromise();
  }

  getCPTAutoCompleteForBenefitInsurance(
    client_id: string,
    payer_id: string,
    cpt: string,
    proc_order: number
  ): Observable<string[]> {
    return this.http
      .get<GET_ORDER_BENEFIT_CPT_DETAILS>(
        `${environment.GET_CLIENT_DATA}/${client_id}/payer/${payer_id}/cpt/${cpt}/proc_order/${proc_order}`
      )
      .pipe(
        map((resp: GET_ORDER_BENEFIT_CPT_DETAILS) =>
          resp.data.map((item: any) => `${item.cpt}`)
        )
      );
  }

  getPayerInsuranceByCPT(
    client_id: string,
    payer_id: string,
    cpt: string,
    proc_order: number
  ): Observable<BenefitPayerProcedure> {
    return this.http
      .get<GET_ORDER_BENEFIT_CPT_DETAILS>(
        `${environment.GET_CLIENT_DATA}/${client_id}/payer/${payer_id}/cpt/${cpt}/proc_order/${proc_order}`
      )
      .pipe(map((resp: GET_ORDER_BENEFIT_CPT_DETAILS) => resp.data[0]));
  }

  calculateAllowable(contract: number, mppr: number): number {
    return +(contract + contract * -(mppr / 100)).toFixed(2);
  }

  async getClientLocations(client_id: string) {
    return this.http
      .get<any>(`${environment.GET_Client_Locations}${client_id}`)
      .toPromise();
  }

  async assignFormToOrder(
    client_id: string,
    order_id: string,
    data: AssignFormToOrderReqData
  ): Promise<GenericResp> {
    return this.http
      .post<GenericResp>(
        `${environment.FORMS}/client/${client_id}/order/${order_id}/assign`,
        data
      )
      .toPromise();
  }

  async getOrderAssignedForms(
    client_id: string,
    patient_id: string,
    order_id: string
  ): Promise<GET_ORDER_ASSIGNED_FORMS_RESP> {
    return this.http
      .get<GET_ORDER_ASSIGNED_FORMS_RESP>(
        `${environment.FORMS}/client/${client_id}/patient/${patient_id}/order/${order_id}`
      )
      .toPromise();
  }

  async getAllPatients(
    practice_id: string,
    date: Date | string,
    name: string,
    page: number = 1,
    limit: number = 10
  ): Promise<PatientPopup> {
    return this.http
      .get<PatientPopup>(
        `${environment.GET_ALL_PATIENTS}/${practice_id}?date=${date}&search=${name}&searchCriteria=patient_name&page=${page}&limit=${limit}`
      )
      .toPromise();
  }

  async getAllOrderStepsWithStatus(
    practice_id: string,
    client_id: string
  ): Promise<GET_ALL_ORDER_STEPS_RESP> {
    return this.http
      .get<GET_ALL_ORDER_STEPS_RESP>(
        `${environment.TOGGLE_ORDER}practice/${practice_id}/client/${client_id}/steps`
      )
      .toPromise();
  }

  async getAllOrderStepsWithStatusByLocation(): Promise<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http
      .get<any>(`${environment.TOGGLE_ORDER}steps/location`, {
        headers: { Authorization: `${user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  uploadAttachment( 
     file: File[],
    practice_id: string,
    clientId: string,
    order_id: string,
    is_location_request: string,
    name: string,
    category: string,
    date_of_service: string,
    patient_id: string, 
    upload_only: boolean ): Observable<any> {

    let header = new HttpHeaders();
    header.append('content-Type','multipart/form-data');
    
    const formData = new FormData();
    formData.append('is_location_request', is_location_request);
    formData.append('upload_only', String(upload_only));
    if (clientId.length > 0) formData.append('client_id', clientId);
    if (practice_id.length > 0) formData.append('practice_id', practice_id);
    if (order_id) formData.append('order_id', order_id);
    // Append new fields if provided
    if (name) formData.append('name', name);
    if (category) formData.append('category', category);
    if (date_of_service) formData.append('date_of_service', date_of_service);
    if (patient_id) formData.append('patient_id', patient_id);
    for (let i = 0; i < file.length; i++) {
      formData.append('files', file[i]);
    }
    return this.http.post<any>(
      `${environment.API_URL}order/${order_id}/attachment`,
      formData,
      { headers: header }
    );
  }

  async deleteOrderAttachment(
    order_id: string,
    attachment_id: number,
    notify_patient: boolean
  ): Promise<GenericResp> {
    return this.http
      .delete<GenericResp>(
        `${environment.API_URL}order/${order_id}/attachment/${attachment_id}`,
        {
          headers: { notify_patient: `${notify_patient}` },
        }
      )
      .toPromise();
  }

  async updatePatient(patient_id: string, body:any): Promise<any> {
    const data = {body};
    const user = JSON.parse(localStorage.getItem('user'));
    return await this.http
      .put<any>(`${environment.API_URL}patient/${patient_id}`, body, {
        headers: { Authorization: `${user.stsTokenManager.accessToken}` },
      })
      .toPromise();
  }

  async updateOrderNotes(
    order_id: string,
    data: ORDER_NOTES_REQ_BODY
  ): Promise<GenericResp> {
    return await this.http
      .put<GenericResp>(
        `${environment.UPDATE_ORDER_NOTES}/${order_id}/notes`,
        data
      )
      .toPromise();
  }

  async createOrderNote(
    order_id: string,
    data: Omit<OrderNote, 'order_id' | 'id'>
  ): Promise<GenericResp> {
    const user = JSON.parse(localStorage.getItem('user'));
    return await this.http
      .post<GenericResp>(
        `${environment.UPDATE_ORDER_NOTES}/${order_id}/note`,
        data,
        {
          headers: { Authorization: `${user.stsTokenManager.accessToken}` },
        }
      )
      .toPromise();
  }

  async getOrderStepNotes(order_id: string): Promise<GenericResp> {
    return await this.http
      .get<GenericResp>(
        `${environment.UPDATE_ORDER_NOTES}/${order_id}/notes`,
        {}
      )
      .toPromise();
  }

  async updateOrderNote(
    order_id: string,
    note_id: string,
    data: Partial<Omit<OrderNote, 'order_id' | 'id'>>
  ): Promise<GenericResp> {
    const user = JSON.parse(localStorage.getItem('user'));

    return await this.http
      .put<GenericResp>(
        `${environment.UPDATE_ORDER_NOTES}/${order_id}/note/${note_id}`,
        data,
        {
          headers: { Authorization: `${user.stsTokenManager.accessToken}` },
        }
      )
      .toPromise();
  }

  async deleteOrderNote(note_id: string): Promise<GenericResp> {
    const user = JSON.parse(localStorage.getItem('user'));
    return await this.http
      .delete<GenericResp>(
        `${environment.UPDATE_ORDER_NOTES}/note/${note_id}`,
        {
          headers: { Authorization: `${user.stsTokenManager.accessToken}` },
        }
      )
      .toPromise();
  }

  async deleteOrder(client_id: string, order_id: string): Promise<GenericResp> {
    return await this.http
      .delete<GenericResp>(
        `${environment.API_URL}client/${client_id}/order/${order_id}`
      )
      .toPromise();
  }

  async getAllOrdersForLocation(
    token: string,
    page: number = 1,
    limit: number = 10,
    orderBy: {},
    search: string,
    searchCriteria: string,
    filterByStatus: {},
    is_archived: boolean
  ): Promise<GET_ALL_ORDERS_RESP> {
    return this.http
      .get<GET_ALL_ORDERS_RESP>(`${environment.UPDATE_ORDER_NOTES}/location`, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        params: {
          page: `${page}`,
          limit: `${limit}`,
          orderBy: JSON.stringify(orderBy),
          search,
          searchCriteria,
          filterByStatus: JSON.stringify(filterByStatus),
          is_archived: `${!!is_archived}`,
        },
      })
      .toPromise();
  }

  downloadCsv(
    practice_id: string,
    page: number = 1,
    limit: number = -1,
    orderBy: {},
    search: string,
    searchCriteria: string,
    filterByStatus: {},
    is_archived: boolean
  ): Observable<Blob> {
    const params = {
      page: `${page}`,
      orderBy: JSON.stringify(orderBy),
      search,
      searchCriteria,
      filterByStatus: JSON.stringify(filterByStatus),
      is_archived: `${!!is_archived}`,
    };

    if (limit !== -1) {
      params['limit'] = limit;
    }

    return this.http.get(
      `${environment.BASE_URL}/practice/${practice_id}/export-csv`,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Bearer ${this.user.stsTokenManager.accessToken}`
        ),
        responseType: 'blob',
        params,
      }
    );
  }

  async getOrderInsuranceForms(
    order_id: string
  ): Promise<GET_ALL_INSURANCE_FORMS_RESP> {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
      Authorization: `${user.stsTokenManager.accessToken}`,
    };

    return this.http
      .get<GET_ALL_INSURANCE_FORMS_RESP>(
        `${environment.Create_Order}/${order_id}/insurance-forms`,
        { headers }
      )
      .toPromise();
  }

  deleteInsuranceForm(id: string, order_id: string): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.http.delete(
      `${environment.BASE_URL}/order/${order_id}/insurance-form/${id}`,
      {
        headers: {
          Authorization: `${user.stsTokenManager.accessToken}`,
        },
      }
    );
  }

  getPatientDocuments(patientId: string): Observable<any> {
    return this.http.get(
      `${environment.API_URL}patient/${patientId}/documents`
    );
  }

}
