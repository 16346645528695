import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transcription-modal',
  templateUrl: './transcription-modal.component.html',
  styleUrls: ['./transcription-modal.component.scss'],
})
export class TranscriptionModalComponent implements OnInit {
  
  @Input() responseData: any;
  parsedData: any;

  constructor(
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {
    
  }

  ngOnInit(): void {
    this.parsedData = typeof this.responseData === 'string' ? JSON.parse(this.responseData) : this.responseData;
  }

}
