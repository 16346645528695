<div class="modal-header">
  <h4 class="modal-title">AI Transcription</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <h5>Orders</h5>
  <p *ngIf="!parsedData?.Order?.length">NONE</p>
  <ul *ngIf="parsedData?.Order?.length">
    <li *ngFor="let order of parsedData.Order">
      <strong>Type:</strong> {{ order.type }} <br />
      <strong>Order:</strong> {{ order.order }} <br />
      <strong>Description:</strong> {{ order.description }}
    </li>
  </ul>

  <h5>Doctor Notes</h5>
  <div *ngIf="parsedData?.doctor_notes">
    <p><strong>History of Present Illness:</strong></p>
    <p>{{ parsedData.doctor_notes['History Of Present Illness']}}</p>

    <p><strong>Physical Examination:</strong></p>
    <p>{{ parsedData.doctor_notes['Physical Examination'] }}</p>

    <p><strong>Orders:</strong></p>
    <p>{{ parsedData.doctor_notes['Orders'] }}</p>

    <p><strong>Assessment/Plan:</strong></p>
    <p>{{ parsedData.doctor_notes['Assessment/Plan'] }}</p>
  </div>

  <h5>Recommendations</h5>
  <div *ngIf="parsedData?.recommendations">
    <p *ngIf="parsedData?.recommendations?.tests?.length"><strong>Tests:</strong></p>
    <ul>
      <li *ngFor="let test of parsedData.recommendations.tests">
        {{ test.name }} - {{ test.reason }}
      </li>
    </ul>

    <p *ngIf="parsedData?.recommendations?.medications?.length"><strong>Medications:</strong></p>
    <ul>
      <li *ngFor="let med of parsedData.recommendations.medications">
        {{ med.name }} - {{ med.dosage }} ({{ med.purpose }})
      </li>
    </ul>

    <p *ngIf="parsedData?.recommendations?.treatments?.length"><strong>Treatments:</strong></p>
    <ul>
      <li *ngFor="let treatment of parsedData.recommendations.treatments">
        {{ treatment.name }} - {{ treatment.description }}
      </li>
    </ul>

    <p *ngIf="parsedData?.recommendations?.considerations?.length"><strong>Considerations:</strong></p>
    <p>{{ parsedData.recommendations.considerations }}</p>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-secondary" (click)="activeModal.close()">Close</button>
</div>
