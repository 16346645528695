<div class="page-title__bar">
  <h2 class="title">Order Management</h2>
</div>

<div class="tab-content" id="myTabContent">
  <div style="margin: 20px">
    <app-order-details-panel
      (reloadGrid)="reloadTable($event)"
      [order]="selectedOrder"
      [assistantInfo]="assistantInfo"
      [assistantUID]="this.user.uid"
      [assistantPractice]="assistantPractice"
      [appointments]="true"
      [tasks]="true"
      [attachments]="true"
      (getPendingOrders)="getPendingOrders()"
    ></app-order-details-panel>
  </div>
  
  <!-- Orders -->
  <div class="right__panel calendar-data__wrapper">
    <div class="table-data__wrapper table-responsive calendar-data--table">
      <div class="d-flex orders-bar">
        <h2 class="title">Orders</h2>
        <div class="user-actions">
          <div class="filter__panel btn-demo" ngbTooltip="Filter" (click)="openFilterModal()">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              [ngClass]="selectedStatus && selectedStatus.status ? 'filtered' : ''"
              xmlns="http://www.w3.org/2000/svg"
              alt="filters"
            >
              <path
                d="M18.3334 2.5H1.66669L8.33335 10.3833V15.8333L11.6667 17.5V10.3833L18.3334 2.5Z"
                stroke="#001737"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="csv__panel btn-demo" ngbTooltip="Download CSV" (click)="downloadCSV()">
            <i class="fas fa-cloud-download" aria-hidden="true"></i>
          </div>
          <select ngbTooltip="Search Criteria" (change)="onChangeSearchCriteria($event)">
            <option value="patient_name">Patient Name</option>
            <option value="provider_name">Provider Name</option>
            <option value="order_summary">Order Summary</option>
            <option value="order_facility">Order Facility</option>
            <option value="mrn">MRN</option>
          </select>
          <div class="input-group">
            <input
              #searchBar
              type="search"
              (keyup.enter)="filterUsers($event)"
              (search)="filterUsers($event, true)"
              [(ngModel)]="searchKeyword"
              class="form-control change"
              placeholder="Search"
            />
            <div class="input-group-append" (click)="filterUsers($event)">
              <span class="input-group-text">
                <img src="../../../assets/icons/daily-screening.svg" alt="search" />
              </span>
            </div>
          </div>
          <div class="archived__panel btn-demo mr-4">
            <div class="d-flex align-items-center">
              <div style="margin-left: 10px;">
              Archived
              <mat-slide-toggle style="margin-left: 15px;" size="lg" color="#073786" #attachmentSwitch (change)="onChangeSwitch()"></mat-slide-toggle>
            </div>
            </div>
          </div>
          <select ngbTooltip="Select Step" (change)="onStepChange($event)">
            <option value="">Select Step</option>
            <option *ngFor="let step of orderStepsArray?.Steps" value="{{ step.title }}">
              {{ step.title }}
            </option>
          </select>
          <select
            *ngIf="this.selectedStep !== ''"
            class="steps-drop-down"
            ngbTooltip="Select Status"
            (change)="onChangeStatus($event)"
          >
            <option value="">Select Status</option>
            <option *ngFor="let status of orderStepsArray?.Status" value="{{ status.id }}">
              {{ status.title }}
            </option>
          </select>
          <button
            type="button"
            ngbTooltip="Add a new Order"
            placement="left"
            class="btn add-order-btn"
            (click)="openAddOrder()"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div [ngClass]="chatShow ? 'd-flex justify-content-center' : ''">
        <app-table-pagination-client
          [headers]="orderTableHeaders"
          [data]="selectedUserOrdersTableData"
          [hasActions]="true"
          [paginateId]="2"
          [orderByFunction]="orderBy"
          [selectContent]="selectOrder"
          [selectedContent]="selectedOrder"
          (createChat)="showChatBox($event)"
          (createInbox)="createInbox($event)"
          [showChatButton]="userRole === 'practice_admin' ? true : false"
          [paginationFirstPage]="firstPage"
          [paginationPrevPage]="prevPage"
          [paginationNextPage]="nextPage"
          [paginationLastPage]="lastPage"
          [paginationCustomPage]="customPage"
          [serverSidePagination]="tablePages"
          [archivedFunction]="archivedFunction"
          [pageSize]="pageSize"
          [currentPage]="1"
          [style]="'inline-flex'"
        >
        </app-table-pagination-client>
        <div
          *ngIf="chatShow"
          class="screen-3 container"
          style="height: 650px; top: 5%; margin-top: 17px; max-width: 360px"
        >
          <button class="btn chat-close-button" (click)="chatShow = false">
            <li class="fa fa-times fa-large"></li>
          </button>
          <button class="sms-button">
            <img
              src="../../../assets/icons/sms.svg"
              alt="sms"
              class="sms-icon"
              ngbTooltip="Send SMS"
              (click)="sendSMS()"
            />
          </button>

          <div style="height: 500px !important; width: 85% !important; position: absolute !important">
            <div #talkjsContainer style="height: 77vh">
              <i>Loading chat...</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
